import { QsHttpService } from '../../../../core/http/qs-http.service';
import { Injectable, inject, signal } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';
import { of } from 'rxjs/internal/observable/of';
import { IDataPayloadMultiple } from 'src/app/core/http/http.interface';
import { IContentMenuItem } from '../../main-navigation.interface';
import { IContent } from 'src/app/content/content.interface';
import { HelperFunctionsService } from 'src/app/core/helper-functions/helper-functions.service';

@Injectable({
  providedIn: 'root'
})
export class SubMenuContentService {
  protected readonly http: QsHttpService = inject(QsHttpService);
  protected readonly _helperFunctions: HelperFunctionsService = inject(HelperFunctionsService);

  industries$ = signal<IDataPayloadMultiple<IContentMenuItem> | null>(null);
  militaries$ = signal<IDataPayloadMultiple<IContentMenuItem> | null>(null);
  newGuides$ = signal<IDataPayloadMultiple<IContent> | null>(null);

  public getIndustries(): Observable<IDataPayloadMultiple<IContentMenuItem>> {
    const params = [
      'sort=name:ASC',
    ];

    if (this.industries$()) {
      return of(this.industries$()!);
    }

    return this.http.get<IDataPayloadMultiple<IContentMenuItem>>(`/industries?${params.join('&')}`)
      .pipe(
        tap((response) => this.industries$.set(response)),
      );
  }

  public getMilitaries(): Observable<IDataPayloadMultiple<IContentMenuItem>> {
    const params = [
      'sort=name:ASC',
    ];

    if (this.militaries$()) {
      return of(this.militaries$()!);
    }

    return this.http.get<IDataPayloadMultiple<IContentMenuItem>>(`/militaries?${params.join('&')}`)
      .pipe(
        tap((response) => this.militaries$.set(response)),
      );
  }

  public getNewGuides(): Observable<IDataPayloadMultiple<IContent>> {
    const params = [
      'populate=coverImage',
      'filters[feature][$eq]=true',
    ];

    if (this.newGuides$()) {
      return of(this.newGuides$()!);
    }

    return this.http.get<IDataPayloadMultiple<IContent>>(`/contents?${params.join('&')}`)
      .pipe(
        map((response) => {
          if (response.data && response.data.length > 0) {
            response.data.forEach((item) => {
              item.attributes.description = this._helperFunctions.truncateText(item.attributes.description, 12);
            });

            return response;
          }

          return {} as unknown as IDataPayloadMultiple<IContent>;
        }),
        tap((response) => this.newGuides$.set(response)),
      );
  }
}
