import { QsHttpService } from '../../core/http/qs-http.service';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { IDataPayloadMultiple, IDataPayloadSingle } from 'src/app/core/http/http.interface';
import { IPrintSolutionsContent } from './print-solutions.interface';
import { IOutreach, IProductViewContent } from './product-view/product-view.interface';

@Injectable({
  providedIn: 'root'
})
export class PrintSolutionsService {
  protected readonly _http: QsHttpService = inject(QsHttpService);

  public getPrintSolutionContent(): Observable<IDataPayloadSingle<IPrintSolutionsContent>> {
    const params = [
      'populate=deep,3',
    ];

    return this._http.get<IDataPayloadSingle<IPrintSolutionsContent>>(`/print-solution?${params.join('&')}`);
  }

  public getPrintFormats(): Observable<IDataPayloadMultiple<IProductViewContent>> {
    const params = [
      'populate=deep,3',
    ];

    return this._http.get<IDataPayloadMultiple<IProductViewContent>>(`/print-products?${params.join('&')}`);
  }

  public getPrintFormat(slug: string): Observable<IDataPayloadMultiple<IProductViewContent>> {
    const params = [
      `filters[slug][$eq]=${slug}`,
      `populate=deep,3`,
    ];

    return this._http.get<IDataPayloadMultiple<IProductViewContent>>(`/print-products?${params.join('&')}`);
  }

  public getOutreach(slug: string): Observable<IOutreach> {
    return this._http.get<IDataPayloadMultiple<IOutreach>>(`/local/outreach/${slug}`)
      .pipe(
        map((response) => {
          if (response.data && response.data.length > 0) {
            return response.data[0].attributes as unknown as IOutreach;
          }

          return {} as unknown as IOutreach;
        }),
      );
  }
}
